import { reactive, toRefs } from 'vue';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';
import { useServices } from './use-services';
import { formatRecord } from '@/utils/formatters';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';

const SERVICE_NAME = 'diagnostic-tests';
const state = reactive({
  tests: [],
  laboratoryTests: [],
  radiologyTests: [],
  archivedTests: [],
});

const populateTestMeasures = () => { // eslint-disable-line
  const query = {
    method: 'find',
    service: 'diagnostic-measures',
    foreignKey: 'test',
    localKey: 'id',
  };
  return query;
};

const populateService = () => {
  const query = {
    method: 'findOne',
    service: 'services',
    foreignKey: 'ref',
    localKey: 'id',
  };
  return query;
};

const populateArchivedService = () => {
  const query = {
    method: 'findOne',
    service: 'services/services-archived',
    foreignKey: 'ref',
    localKey: 'id',
  };
  return query;
};

function mapTests (test) {
  return {
    ...test,
    formattedCreatedAt: test.createdAt ? format(new Date(test.createdAt), 'MMM dd, yyyy') : null,
  };
}

function mapArchivedTests (test) {
  return {
    ...test,
    formattedCreatedAt: test.createdAt ? format(new Date(test.createdAt), 'MMM dd, yyyy') : null,
    formattedArchivedAt: test.archiedAt ? format(new Date(test.archiedAt), 'MMM dd, yyyy') : null,
  };
}

export function useDiagnosticTests () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();
  const { listArchivedItems } = useServices();

  async function listTests (type) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      type,
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      $populate: {
        service: populateService(),
        archivedService: populateArchivedService(),
        measures: populateTestMeasures(),
      },
    };
    // future update: normalize populated
    const result = await sdk?.list(SERVICE_NAME, query);
    const mappedTests = result.data.map(mapTests);
    state.tests = mappedTests;
    state[`${type}Tests`] = cloneDeep(mappedTests);
  }

  async function listArchivedTests (type, subtype) {
    const result = await listArchivedItems(type, subtype);
    if (!result) return;
    state.archivedTests = result.map(mapArchivedTests);
  }

  async function getTest (test, type) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const testId = test?.id;
    if (!testId) return;
    const payload = {
      type,
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      test: testId,
      $populate: {
        measures: populateTestMeasures(),
      },
    };
    const res = sdk?.get(SERVICE_NAME, payload);
    return res;
  }

  async function createTest (data) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const payload = {
      facility: facilityId,
      ...data,
    };
    const result = await sdk?.create(SERVICE_NAME, payload);
    state.tests.unshift(mapTests(result));
    return result;
  }

  async function updateTest (id, data) {
    const result = await sdk?.update(SERVICE_NAME, id, data);
    const index = state.tests.findIndex((item) => item.id === id);
    const test = formatRecord(result);
    state.tests[index] = test;
    return test;
  }

  async function removeTest (id) {
    await sdk?.delete(SERVICE_NAME, id);
    const index = state.tests.findIndex((item) => item.id === id);
    state.tests.splice(index, 1);
  }

  return {
    ...toRefs(state),
    listTests,
    listArchivedTests,
    getTest,
    createTest,
    updateTest,
    removeTest,
  };
}
